import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'lib/Auth';
import moment from 'moment';

declare global {
  interface Window {
    IndeCX?: any;
  }
}

const useNPSWidget = () => {
  const authState = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (
      !isLoggedIn() ||
      !authState ||
      authState.featureToogle?.nps !== 'True' ||
      !authState.userInfo
    )
      return;

    const { userInfo, userPermissions, network } = authState;

    const indecxConfig = {
      key: '651daf9b13c7840027016786',
      action: '66fab828dc0da80025171aba',
      name: userInfo?.user?.name || 'nome',
      email: (userInfo as any)?.contacts?.email || 'Email não informado',
      phone: (userInfo as any)?.contacts?.phone || 'Telefone não informado',
      indicators: {
        cliente: network.clientName || 'Cliente não informada',
        departamento: (userInfo?.department as any)?.description || 'Departamento não informado',
        cargo: (userInfo?.occupation as any)?.description || 'Cargo não informado',
        aniversario: formatDate(userInfo?.user?.birthday) || 'Aniversário não informado',
        ID: userInfo?.user?.userId || 'ID não informado',
        dataAtivacao:
          formatDate(userInfo?.user?.lgpdTermsAcceptedDate) || 'Data de ativação não informada',
        ...mapPermissions(userPermissions),
      },
    };

    loadIndeCXWidget(indecxConfig);
  }, [authState]);
};

const formatDate = (dateString: any): string => {
  if (!dateString) return 'Data não informada';

  return moment(dateString).format('DD/MM/YYYY');
};

const mapPermissions = (permissions: any) => ({
  criarBanner: !!permissions['CreateBanner'],
  criarGrupo: !!permissions['CreateGroup'],
  criarAgendamentoDePost: !!permissions['CreatePostSchedule'],
  gerenciarRede: !!permissions['ManageNetwork'],
  gerenciarFuncoes: !!permissions['ManageRoles'],
  gerenciarUsuarios: !!permissions['ManageUsers'],
  gerenciamentoDeConteudo: !!permissions['ContentManagement'],
  criarPostFixo: !!permissions['CreateFixedPost'],
  gerenciarGrupos: !!permissions['ManageGroups'],
  criarEnquete: !!permissions['CreatePoll'],
  criarPostPublico: !!permissions['CreatePublicPost'],
  alterarCapa: !!permissions['ChangeCover'],
  criarNoticia: !!permissions['CreateNews'],
});

const loadIndeCXWidget = (config: any) => {
  if (document.getElementById('script-indecx')) return;

  const script = document.createElement('script');
  script.id = 'script-indecx';
  script.src = 'https://indecx-widget.s3.amazonaws.com/widget.js';
  script.async = true;

  script.onload = () => window.IndeCX && window.IndeCX(config);
  document.body.appendChild(script);
};

export default useNPSWidget;
