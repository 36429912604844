import loadable from '@loadable/component';
import { useFeatureToggle } from 'hooks';
import FeedComponent from 'components/Feed';

const FeedOld = loadable(
  () => import(/* webpackChunkName: "FeedOld" */ 'components/Feed/Feed_old')
);

const Feed = () => {
  const [newFeed] = useFeatureToggle('new_feed');

  // console.log('feed estamos aqui');

  if (newFeed) return <FeedComponent />;

  return <FeedOld />;
};

export default Feed;
