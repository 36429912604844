import React, { forwardRef, Ref, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { convertToRaw, EditorState } from 'draft-js';
import type { MentionData } from '@draft-js-plugins/mention';
import produce from 'immer';
import { debounce, differenceWith, filter } from 'lodash/fp';
import { v4 } from 'uuid';

import { useAuth } from 'hooks';
import { POST_TYPES, PROFILE_TYPES } from 'lib/constants';
import { useDispatch, useSelector } from 'store';
import { cancelEdit, updatePost } from 'store/actions/postsActions';
import { makeGetPostUISelector } from 'store/selectors/postSelectors';
import { cancelEditPendingPost } from 'store/actions/uiActions';
import { fetchMedia, getEntities, transformEditorContent } from 'lib/helper';
import PostHeader from 'components/layout/Main/Posts/PostHeader/PostHeader';
import EditPostForm from 'components/layout/Main/EditPost/EditPostForm';
import type { UploadButtonProps } from 'components/common/Attachments/AttachmentsActions';
import * as customNotification from 'components/layout/Common/CustomNotification';

import PostEditorPlugin from '../PostEditorPlugin/PostEditorPlugin';
import * as S from './EditPostModalStyles';

export type EditPostModalProps = {
  postInfo: PostInfo;
};

export type EditPostModalRef = {
  open: () => void;
};

const postUISelector = makeGetPostUISelector();

function EditPostModal({ postInfo }: EditPostModalProps, ref: Ref<EditPostModalRef>) {
  const { t } = useTranslation('feed');
  const { token, networkSettings } = useAuth();

  const { postId } = postInfo.post;

  const [state, setState] = useReducer(reducer, initialState, initializer(postInfo, token!));

  const dispatch = useDispatch();
  const { isEditing } = useSelector(state => postUISelector(state, postId));

  const previewLink = useRef(
    debounce(350, async (link: string) => {
      setState({ isLoadingPreview: true });

      const previewApi = await import('lib/api/preview');
      const preview = await previewApi.previewLink(link);

      setState({ isLoadingPreview: false });

      if (!preview || typeof preview === 'number') {
        return;
      }

      preview.new = true;

      resetAttachments();
      setState({
        type: 'set-attachment',
        payload: { attachments: [preview], attachmentType: 'link' },
      });
    })
  ).current;

  const getAttributes = () => {
    const { editorState, attachments, attachmentType, deletedFiles } = state;
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    setState({ errorMessage: '' });

    const newFiles = attachments.filter(file => file.new);
    const attributes = {
      description: JSON.stringify(rawContent),
      descriptionText: contentState.getPlainText(),
      postId,
      type: postInfo.post.type,
      pending: postInfo.post.pending,
      pinned: postInfo.post.pinned,
      originalPost: JSON.stringify(postInfo),
      _extra: {} as Record<string, any>,
    };

    if (newFiles && newFiles.length > 0) {
      attributes._extra.files = newFiles.reverse();
      attributes._extra.fileType = attachmentType;
    }
    if (deletedFiles.length > 0) {
      attributes._extra.toRemove = deletedFiles;
    }

    if (postInfo.post.scheduledDate) {
      Object.assign(attributes, { scheduledDate: postInfo.post.scheduledDate });
    }

    if (postInfo.post.pendingType || postInfo.post.pendingType > 0) {
      Object.assign(attributes, { pendingType: postInfo.post.pendingType });
    }

    const mentions = filter(em => em.type === 'mention', rawContent.entityMap);

    if (postInfo.post.type === POST_TYPES.private) {
      attributes._extra.targetProfileIds = postInfo.targetUsers.map(
        tgu => tgu.postTarget.profileId
      );

      if (mentions.length > 0) {
        const newTargets = differenceWith(
          (m, tgu) => m.data.mention.userId === tgu.postTarget.profileId,
          mentions,
          postInfo.targetUsers
        );

        attributes._extra.targetProfileIds = [
          ...newTargets.map(t => t.data.mention.userId),
          ...postInfo.targetUsers.map(tgu => tgu.postTarget.profileId),
        ];
      }
    }

    return attributes;
  };

  const postResult = (status?: number) => {
    setState({ isSending: false });

    switch (status) {
      case 200:
        if (postInfo.post.pending) {
          dispatch(cancelEditPendingPost(postId));
        } else {
          dispatch(cancelEdit(postId));
        }
        break;
      case 202: {
        customNotification.renderToast({
          type: customNotification.TYPE_VIDEO_PROCESSING,
          title: t('default:post.processingVideoTitle'),
          body: t('default:post.processingVideo'),
        });

        if (postInfo.post.pending) {
          dispatch(cancelEditPendingPost(postId));
        } else {
          dispatch(cancelEdit(postId));
        }
        break;
      }
      default:
        setState({ errorMessage: t('default:post.errorSend') });
        break;
    }
  };

  const submitPost = (attributes: ReturnType<typeof getAttributes>) => {
    setState({ isSending: true, errorMessage: '' });

    dispatch(updatePost(attributes, postResult, () => {}));
  };

  const getErrorMessage = (forbiddenExtensions: string[], maxFileSize: number) => {
    return t('default:files.forbiddenFiles', {
      extensions: forbiddenExtensions.join(', '),
      size: maxFileSize,
    });
  };

  const closeHandler = () => {
    setState({ type: 'reset-state' });
    setState(initializer(postInfo, token!));
    if (postInfo.post.pending) {
      dispatch(cancelEditPendingPost(postId));
    } else {
      dispatch(cancelEdit(postId));
    }
  };

  const editorChangeHandler = (newEditorState: EditorState, linksList: string[]) => {
    const linkEntity = getEntities(newEditorState, 'LINK').shift();

    if (linkEntity || linksList.length > 0) {
      const url = linkEntity?.entity.getData().url ?? linksList.pop();

      if (url !== state.currentLink) {
        setState({ currentLink: url });
        previewLink(url);
      }
    } else {
      previewLink.cancel();
      if (state.attachmentType === 'link') {
        setState({ currentLink: '' });
        resetAttachments();
      }
    }

    setState({ editorState: newEditorState });
  };

  const fileUploaderHandler: UploadButtonProps['onFileUploaded'] = (
    acceptedFiles,
    fileType,
    rejectedFiles
  ) => {
    const { maxPhotosPermittedInPost, maxPostFileSize } = networkSettings;
    const { attachments } = state;

    if (acceptedFiles.length > 0) {
      setState({ errorMessage: '' });

      const totalFileCount = acceptedFiles.length + attachments.length;
      let allowedFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && fileType === 'image') {
        allowedFiles = acceptedFiles.slice(0, maxPhotosPermittedInPost - attachments.length);
      }

      const newFiles = allowedFiles.map<PostFile>(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      if (fileType !== 'image') {
        resetAttachments();
      }

      setState({
        type: 'set-attachment',
        payload: { attachments: newFiles, attachmentType: fileType },
      });
    } else if (rejectedFiles && rejectedFiles.length > 0) {
      resetAttachments();
      if (fileType === 'video') {
        setState({
          errorMessage: t('default:files.videoSizeAttachment', {
            size: maxPostFileSize,
          }),
        });
      } else {
        setState({
          errorMessage: t('default:files.forbiddenAttachment'),
        });
      }
    }
  };

  const rejectedFileHandler = () => {
    resetAttachments();

    setState({
      errorMessage: getErrorMessage(
        networkSettings.forbiddenExtensions,
        networkSettings.maxPostFileSize
      ),
    });
  };

  const removeFileHandler = (attachment: PostFile) => {
    URL.revokeObjectURL(attachment.preview);

    setState({ type: 'remove-attachment', payload: attachment });
  };

  const resetAttachments = () => {
    setState({ type: 'reset-attachments' });
  };

  const renderErrorMessage = () => {
    const { errorMessage } = state;
    if (!errorMessage) {
      return null;
    }
    return (
      <S.ContainerPreview>
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
      </S.ContainerPreview>
    );
  };

  const searchMentionsHandler = useRef(
    debounce(350, async ({ value }: { value: string }) => {
      if (!value) return;

      const { author, page } = postInfo;
      const params: Parameters<typeof mentionApi.getUserList>[0] = { name: value };

      if (page && !page.public) {
        params.pageId = page.pageId;
      }

      if (
        postInfo.post.type === POST_TYPES.group &&
        postInfo.post.private &&
        author.type !== PROFILE_TYPES.group &&
        (!page?.public ?? false)
      ) {
        return;
      }

      const mentionApi = await import('lib/api/mention');

      const mentionResult = await mentionApi.getUserList(params);

      if (!mentionResult.ok) {
        setState({ suggestions: [] });
        return;
      }

      const mentionList = mentionResult.data;

      const suggestions = mentionList.map(mention => ({
        userId: mention.userId,
        name: mention.name,
      }));

      setState({ suggestions });
    })
  ).current;

  const savePostHandler = (survey?: Survey) => {
    const attributes = getAttributes();

    Object.assign(attributes._extra, { surveyInfo: survey });

    submitPost(attributes);
  };

  return (
    <Modal
      destroyOnClose
      centered
      visible={isEditing}
      title={t('editor.editPost')}
      footer={null}
      width={744}
      onCancel={closeHandler}
    >
      <PostHeader css={S.postHeader} postId={postId} hideDropdown />
      <PostEditorPlugin
        editorProps={{
          editorState: state.editorState,
          editorDisabled: state.isSending,
          onTextChange: editorChangeHandler,
        }}
        attachmentProps={{
          attachmentsDisabled: state.isSending || !!postInfo.post.survey,
          attachments: state.attachments,
          attachmentType: state.attachmentType,
          loading: state.isLoadingPreview,
          onFileUploaded: fileUploaderHandler,
          onFileRejected: rejectedFileHandler,
          onFileRemoved: removeFileHandler,
          resetAttachments: resetAttachments,
        }}
        mentionProps={{
          mentionsOpen: state.mentionOpen,
          mentionSuggestions: state.suggestions,
          onMentionSearch: searchMentionsHandler,
          onMentionOpen: open => {
            setState(({ suggestions }) => ({
              mentionsOpen: open,
              suggestions: !open ? [] : suggestions,
            }));
          },
        }}
      />
      <EditPostForm
        disabled={state.editorState.getCurrentContent().getPlainText().trim().length === 0}
        isSurvey={!!postInfo.post.survey}
        loading={state.isSending}
        survey={postInfo.survey}
        onCancel={closeHandler}
        onSave={savePostHandler}
      />
      {renderErrorMessage()}
    </Modal>
  );
}

export default forwardRef(EditPostModal);

type State = typeof initialState;
type StateValue = Partial<State> | ((prevState: State) => Partial<State>) | Action;
type AttachmentType = 'image' | 'file' | 'video' | 'link' | 'survey';
type Attachments = Array<LinkPreview | PostFile>;

type Action =
  | {
      type: 'set-attachment';
      payload: { attachmentType: AttachmentType; attachments: Attachments };
    }
  | {
      type: 'remove-attachment';
      payload: { index: string | number };
    }
  | {
      type: 'reset-attachments';
    }
  | {
      type: 'reset-state';
    };

const initialState = {
  editorState: EditorState.createEmpty(),
  attachments: [] as Attachments,
  attachmentType: undefined as undefined | AttachmentType,
  deletedFiles: [] as { id: string | number; description: string }[],
  currentLink: '',
  mentionOpen: true,
  suggestions: [] as MentionData[],
  errorMessage: '',
  isSending: false,
  isLoadingPreview: false,
};

const mediaTypes: Record<Exclude<MediaTypes, 5 | 6>, AttachmentType> = {
  1: 'image',
  2: 'video',
  4: 'file',
};

function reducer(state: State, value: StateValue) {
  if (typeof value === 'function') {
    return { ...state, ...value(state) };
  }

  if ('type' in value) {
    return immerState(state, value);
  }

  return { ...state, ...value };
}

function immerState(state: State, action: Action) {
  return produce(state, draft => {
    switch (action.type) {
      case 'set-attachment': {
        const { attachments, attachmentType } = action.payload;
        const currentType = draft.attachmentType;
        draft.attachmentType = attachmentType;

        if (currentType === 'image' && attachmentType === 'image') {
          draft.attachments = draft.attachments.concat(attachments);
        } else {
          draft.attachments = attachments;
        }

        break;
      }
      case 'remove-attachment': {
        const { index } = action.payload;
        const currentAttachments = draft.attachments as PostFile[];
        const newAttachments = currentAttachments.filter(att => att.index !== index);

        draft.attachments = newAttachments;
        if (newAttachments.length <= 0) draft.attachmentType = undefined;

        if (typeof index === 'number') {
          draft.deletedFiles.push({ id: index, description: 'media' });
        }

        break;
      }
      case 'reset-attachments': {
        const { attachmentType } = draft;
        const attachments = draft.attachments as PostFile[];

        let filesToDelete: typeof draft.deletedFiles = [];
        if (attachments && attachmentType !== 'link') {
          filesToDelete = attachments
            .filter(att => typeof att.index === 'number')
            .map(attachment => ({
              id: attachment.index,
              description: 'media',
            }));
        }

        draft.attachmentType = undefined;
        draft.attachments = [];
        draft.deletedFiles = draft.deletedFiles.concat(filesToDelete);

        break;
      }
      case 'reset-state':
      default:
        return initialState;
    }
  });
}

const initializer = (postInfo: PostInfo, token: string) => (state: State) => {
  let attachmentType: AttachmentType | undefined = undefined;
  let attachments: Array<PostFile | LinkPreview> = [];
  if (postInfo.media) {
    attachmentType = mediaTypes[postInfo.media[0].mediaType as keyof typeof mediaTypes];

    attachments = postInfo.media.map(media => ({
      index: media.mediaId,
      name: media.originalFileName,
      size: media.fileSize,
      preview: fetchMedia(media.mediaId, token),
      new: false,
    }));
  } else if (postInfo.linkInfo) {
    attachmentType = 'link';
    const { linkInfo } = postInfo;

    attachments = [
      {
        title: linkInfo.title,
        description: linkInfo.description,
        images: [linkInfo.imageUrl],
        url: linkInfo.url,
        type: linkInfo.type,
      },
    ];
  } else if (postInfo.survey) {
    attachmentType = 'survey';
  }

  return {
    ...state,
    editorState: transformEditorContent(postInfo.post.description, postInfo.mentions),
    attachments,
    attachmentType,
  };
};
