import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Layout, Tabs } from 'antd';
import loadable from '@loadable/component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { useAuth } from 'hooks';
import { ITEM_TYPES, PROFILE_TYPES } from 'lib/constants';
import { getScheduledPosts, tabChanged } from 'store/actions/postsActions';
import { markAsViewed } from 'lib/api/notification';
import type { TabFilter } from 'store/types/postTypes';
import { setFeedCounters } from 'store/actions/uiActions';

import Posts from 'components/Posts';
import Banner from 'components/layout/Main/Banner/Banner';
import Composer from 'components/common/ComposerWrapper';

import { useFeed } from './useFeed';
import { useFeedNotification } from './useFeedNotification';
import Sidebar from './Sidebar';
import HighlightedPosts from './HighlightedPosts';
import * as S from './FeedStyles';

const DrawerActions = loadable(
  () => import(/* webpackChunkName: "DrawerActions" */ './DrawerActions')
);
const Widgets = loadable(() => import(/* webpackChunkName: "FeedWidgets" */ './Widgets/Widgets'));
const Drawer = loadable(() => import(/* webpackChunkName: "FeedDrawer" */ './FeedDrawer'));
const WelcomeModal = loadable(
  () => import(/* webpackChunkName: "FeedWidgets" */ './Modal/WelcomeModal')
);

function Feed() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { userId, networkSettings } = useAuth();

  const [drawerVisible, setDrawerVisible] = useState(false);

  const activeTab = useSelector((state: RootState) => state.posts.currentTab);
  const resultsTabVisible = useSelector((state: RootState) => state.ui.feed.resultsTabVisible);
  const { siderCollapsed } = useSelector((state: RootState) => state.ui);
  const feedCounters = useSelector((state: RootState) => state.ui.feed.counters);

  const { showWelcomeModal, closeWelcomeModal } = useFeed();
  useFeedNotification();

  const openScheduledPostsDrawerHandler = () => {
    dispatch(getScheduledPosts(1, { profileTypes: [PROFILE_TYPES.user, PROFILE_TYPES.publisher] }));
    setDrawerVisible(true);
  };

  const changeTabHandler = async (activeTab: string) => {
    dispatch(tabChanged(activeTab as TabFilter));

    if (activeTab !== 'search' && (feedCounters.newsCount > 0 || feedCounters.myFeedCount > 0)) {
      const fromType = {
        news: [PROFILE_TYPES.group, PROFILE_TYPES.publisher],
        'my-feed': [PROFILE_TYPES.user],
      };

      await markAsViewed(userId, {
        itemType: ITEM_TYPES.post,
        fromTypes: fromType[activeTab as Exclude<TabFilter, 'search'>],
      });

      dispatch(
        setFeedCounters({
          newsCount: activeTab === 'news' ? 0 : feedCounters.newsCount,
          myFeedCount: activeTab === 'my-feed' ? 0 : feedCounters.myFeedCount,
        })
      );
    }
  };

  useEffect(() => {
    const btn = document.getElementById('btnSearchTab');
    const eRedirect = document.getElementById('tabsMenu');
    if (btn && activeTab === 'search') {
      window.scroll({
        top: eRedirect !== null ? eRedirect.offsetTop + 20 : 0,
        behavior: 'smooth',
      });
    }
  });

  const closeTabHandler = async () => {
    const { changeFeedConfig } = await import('store/actions/uiActions');
    dispatch(tabChanged('news'));
    dispatch(changeFeedConfig({ resultsTabVisible: false }));
  };

  return (
    <>
      <Layout hasSider style={{ position: 'relative' }}>
        <Helmet title="Home" />
        <Sidebar />
        <S.PostsContent siderCollapsed={siderCollapsed}>
          <S.InnerPostsContent>
            <Banner />
            {networkSettings.allowSendingPosts && (
              <>
                <Composer storeKey="NEW_POST" />
                <DrawerActions onShowScheduledPosts={openScheduledPostsDrawerHandler} />
              </>
            )}
            <HighlightedPosts />

            <Tabs
              destroyInactiveTabPane
              activeKey={activeTab}
              tabBarStyle={{ fontWeight: 600 }}
              onChange={changeTabHandler}
              style={{ overflow: 'unset' }}
              className="TabsFeed"
              id="tabsMenu"
            >
              <Tabs.TabPane
                tab={<TabTitle title={t('post.news')} count={feedCounters.newsCount} />}
                key="news"
              >
                <Posts profileType={[2, 3]} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={<TabTitle title={t('post.myFeed')} count={feedCounters.myFeedCount} />}
                key="my-feed"
              >
                <Posts profileType={1} />
              </Tabs.TabPane>
              {resultsTabVisible && (
                <Tabs.TabPane
                  key="search"
                  tab={
                    <>
                      {t('post.results')}
                      <Button
                        type="text"
                        id="btnSearchTab"
                        size="small"
                        shape="circle"
                        icon={
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="anticon"
                            style={{ margin: '0 auto' }}
                          />
                        }
                        onClick={closeTabHandler}
                      />
                    </>
                  }
                >
                  <Posts />
                </Tabs.TabPane>
              )}
            </Tabs>
            <S.BackTop />
          </S.InnerPostsContent>
          <Widgets />
        </S.PostsContent>
      </Layout>
      <Drawer
        title={t('post.schedule.scheduledPosts')}
        visible={drawerVisible}
        onClose={setDrawerVisible}
      />
      <WelcomeModal visible={showWelcomeModal} onWelcomeClick={closeWelcomeModal} />
      {/* {isAllowNotification()} */}
    </>
  );
}

export default Feed;

type TabTitleProps = {
  title: string;
  count: number;
};

const TabTitle = React.forwardRef<HTMLSpanElement, TabTitleProps>(({ title, count }, ref) => {
  return (
    <>
      <S.TabTitle ref={ref}>{title}</S.TabTitle>
      {count > 0 && <S.TabCount>{count > 99 ? '+99' : count}</S.TabCount>}
    </>
  );
});
